import initBookmark from '../bookmark/bookmark'
import { renderToastMessage } from '../toast-message/toast-message'
import normalizeWordpressTimestamp from '../../../../lib/normalize-wordpress-timestamp'
import {toFarsiDate} from '../../../../lib/nunjucks-datetime'

const sectionFilter = document.querySelector('[data-section-filter]')
const isSupported = typeof window.URL === 'function'
let apiEndpoint
const startDate = new Date()
startDate.setFullYear(2010)
startDate.setMonth(0)
const now = new Date()
const yearSelect = document.querySelector('[data-section-filter-year]')
const sectionFilterResults = document.querySelector('[data-section-filter-results]')
const sectionFilterResultList = document.querySelector('[data-section-filter-result-list]')
const cardTemplate = document.querySelector('[data-card-secondary-template]')
const noResultMessageElement = document.querySelector('[data-section-filter-no-results]')
const sectionFilterTop = document.querySelector('[data-section-filter-top]')
const nextPageButton = document.querySelector('[data-section-filter-next-page]')
const previousPageButton = document.querySelector('[data-section-filter-previous-page]')
const IS_LOADING_CLASS = 'is-loading'
const IS_VISIBLE_CLASS = 'is-visible'
const IS_ACTIVE_CLASS = 'is-active'
let sectionFilterErrorMessage

const initSectionFilter = function () {
  if (!sectionFilter || !isSupported) {
    return
  }

  sectionFilter.classList.add('is-enhanced')
  const categoryType = sectionFilter.getAttribute('data-section-filter-category-type')
  const categoryId = sectionFilter.getAttribute('data-section-filter-category-id')
  apiEndpoint = `/wp-json/wp/v2/posts/?${categoryType}=${categoryId}&_fields=id,featured_image,format,date,date_gmt,post_title,post_excerpt,post_categories,post_short_title,post_reading_time&per_page=100`
  sectionFilterErrorMessage = sectionFilter.getAttribute('data-section-filter-error-message')

  window.addEventListener('popstate', loadResults)
  sectionFilter.addEventListener('submit', onSubmit)
  yearSelect.addEventListener('change', disableFutureMonths)

  document.querySelectorAll('[data-section-filter-pagination-link]').forEach(link => {
    link.addEventListener('click', onPaginationClick)
  })

  setYearOptions()

  const month = getParameterByName('month')
  const year = getParameterByName('year')

  if (month && year) {
    loadResults()
  } else {
    setPagination()
    disableFutureMonths()
  }
}

function onSubmit(e) {
  e.preventDefault()

  const selectedMonth = e.target.month.value
  const selectedYear = e.target.year.value

  setUrl(selectedMonth, selectedYear)
  loadResults()
}

function loadResults() {
  sectionFilterResults.classList.add(IS_LOADING_CLASS)

  const month = getParameterByName('month')
  const year = getParameterByName('year')

  if (month && year) {
    sectionFilter.year.value = year
    sectionFilter.month.value = month

    const startOfTheMonth = new Date(year, month, 1)
    const endOfTheMonthDate = lastDayOfTheMonth(startOfTheMonth)

    const url = new URL(
      `${apiEndpoint}&after=${startOfTheMonth.toISOString()}&before=${endOfTheMonthDate.toISOString()}`,
      process.env.WP_INSTANCE_URL
    )

    fetch(url)
      .then(res => res.json())
      .then(posts => {
        if (posts.length) {
          noResultMessageElement.classList.remove(IS_VISIBLE_CLASS)

          const htmlAsList = posts.map(post => {
            const cardTemplateData = JSON.parse(cardTemplate.content.textContent)
            const cardHtml = cardTemplateData.card
            const featuredImage = post.featured_image ? post.featured_image : cardTemplateData.fallbackImage
            return cardHtml
              .replace(/__id__/g, post.id)
              .replace(/__post_short_title__/g, post.short_title ? post.short_title : post.post_title)
              .replace(/__category__/g, post.post_categories[0].name)
              .replace(/__post_date_gmt__/g, toFarsiDate(normalizeWordpressTimestamp(post)))
              .replace(/__small_image_src__/g, featuredImage.small.src)
              .replace(/__small_image_width__/g, featuredImage.small.width)
              .replace(/__small_image_height__/g, featuredImage.small.height)
              .replace(/__medium_image_src__/g, featuredImage.medium.src)
              .replace(/__medium_image_width__/g, featuredImage.medium.width)
              .replace(/__medium_image_height__/g, featuredImage.medium.height)
              .replace(/__large_image_src__/g, featuredImage.large.src)
              .replace(/__large_image_width__/g, featuredImage.large.width)
              .replace(/__large_image_height__/g, featuredImage.large.height)
              .replace(/__image_alt__/g, featuredImage.alt ? featuredImage.alt : '')
              .replaceAll('//u/wp-content/', '/u/wp-content/') // Fixes srcset URLs without affecting src URLs.
          }).join('')

          sectionFilterResultList.innerHTML = htmlAsList

          sectionFilterTop.scrollIntoView({behavior: 'smooth'})
          initBookmark()

        } else {
          sectionFilterResultList.innerHTML = ''
          noResultMessageElement.classList.add(IS_VISIBLE_CLASS)
          sectionFilterResultList.classList.add(IS_ACTIVE_CLASS)
        }
        sectionFilterResults.classList.remove(IS_LOADING_CLASS)
        sectionFilterResultList.classList.add(IS_ACTIVE_CLASS)

        document.querySelector('[rel="canonical"]').setAttribute('href', window.location.href)
      })
      .catch(() => {
        sectionFilterResults.classList.remove(IS_LOADING_CLASS)
        renderToastMessage(sectionFilterErrorMessage)
      })
  } else {
    sectionFilterResults.classList.remove(IS_LOADING_CLASS)
    sectionFilterResultList.classList.remove(IS_ACTIVE_CLASS)
  }

  setPagination()
  disableFutureMonths()
}

function setPagination() {
  const selectedMonth = getParameterByName('month') ? getParameterByName('month') : now.getMonth()
  const selectedYear = getParameterByName('year') ? getParameterByName('year') : now.getFullYear()
  const nextDate = new Date(selectedYear, parseInt(selectedMonth) + 1, 1)
  const previousDate = new Date(selectedYear, parseInt(selectedMonth) - 1, 1)

  if (nextDate > now) {
    nextPageButton.setAttribute('disabled', true)
    nextPageButton.removeAttribute('href')
  } else {
    nextPageButton.removeAttribute('disabled')
    nextPageButton.setAttribute('href',  `${window.location.origin}${window.location.pathname}?month=${nextDate.getMonth()}&year=${nextDate.getFullYear()}`)
  }

  if (previousDate.getFullYear() <= 2010) {
    previousPageButton.setAttribute('disabled', true)
    previousPageButton.removeAttribute('href')
  } else {
    previousPageButton.removeAttribute('disabled')
    previousPageButton.setAttribute('href',  `${window.location.origin}${window.location.pathname}?month=${previousDate.getMonth()}&year=${previousDate.getFullYear()}`)
  }
}

function onPaginationClick(e) {
  e.preventDefault()
  const url = new URL(e.target.href)
  const month = url.searchParams.get('month')
  const year = url.searchParams.get('year')

  setUrl(month, year)
  loadResults()
}

function setUrl(month, year) {
  const newUrl = `${window.location.origin}${window.location.pathname}?month=${month}&year=${year}`
  window.history.pushState({ month, year }, '', newUrl)
}

function disableFutureMonths() {
  const isThisYear = parseInt(sectionFilter.year.value) === now.getFullYear()
  const leftOverMonths = isThisYear ? 11 - now.getMonth() : 0
  for (let i = 0; i < 11; i++) {
    const monthOption = document.querySelector(`[data-section-filter-month] option[value="${11 - i}"]`)
    if (i < leftOverMonths) {

      if (monthOption.selected) {
        sectionFilter.month.value = ''
      }

      monthOption.setAttribute('disabled', 'disabled')
    } else {
      monthOption.removeAttribute('disabled')
    }
  }
}

function setYearOptions() {
  const pastYearsCount = new Date(now - new Date(startDate)).getFullYear() - 1970
  let years = []
  for (let i = 0; i < pastYearsCount; i++) {
    const date = new Date(now.getFullYear() - i, 1, 1)
    const year = date.getFullYear()
    years.push(`<option value="${year}">${year}</option>`)
  }

  yearSelect.innerHTML = years.join('')
}

function getParameterByName(name) {
  const url = new URL(window.location.href)
  return url.searchParams.get(name)
}

function lastDayOfTheMonth(date) {
  return new Date(date.getFullYear(), date.getMonth() + 1, 1)
}

export default initSectionFilter
